<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>

    <div v-else>
      <v-card class="elevation-0">
        <PrintViewBar
          v-if="Invoice"
          :showEmailBtn="showEmailBtn"
          :id="Invoice.id"
          type="invoice"
          :title="`Packing Slip # ${this.Invoice.localId}`"
          :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name"
          :email="Invoice.Customer.email"
          :obj="Invoice.Customer"
          :text="`${currentBusiness.name} Packing slip. click here to view`"
        />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase"
                    >{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  PACKING SLIP
                </div>
                <div class="font-weight-regular">
                  <div class="font-weight-medium">BILL TO</div>
                  <div>{{ Invoice.Customer.name }}</div>
                  <div>{{ Invoice.Customer.address }}</div>
                  <div v-if="Invoice.Customer.city">
                    {{ Invoice.Customer.city }},<span class="text-uppercase"
                      >{{ Invoice.Customer.state }}
                    </span>
                    {{ Invoice.Customer.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img
                    height="120"
                    class="logo"
                    :src="currentBusiness.imageUrl"
                    alt=""
                  />
                </div>
                <div class="barcode mt-5">{{ Invoice.localId }}</div>
                <div class="font-weight-regular">
                  <v-layout row>
                    <v-flex>
                      <div class="text-right">INVOICE #</div>
                      <div class="text-right">DATE:</div>
                      <div class="text-right">SALES REP:</div>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="text-left">{{ Invoice.localId }}</div>
                      <div class="text-left">
                        {{ Invoice.date | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-left">{{ Invoice.User.name }}</div>
                    </v-flex>
                  </v-layout>
                  <v-switch
                    class="text-right no-print"
                    v-model="showBarcode"
                    hide-details
                    :label="showBarcode ? 'Hide Barcode' : 'Show Barcode'"
                  >
                  </v-switch>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table
                  :dense="!showBarcode"
                  :headers="headers"
                  sort-by="name"
                  :itemsPerPage="Invoice.InvoiceDetails.length"
                  hide-default-footer
                  :items="Invoice.InvoiceDetails"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-left">
                        <div>
                          <div
                            v-if="showBarcode && item.imeiNumber"
                            class="row-barcode"
                          >
                            *{{ item.imeiNumber }}*
                          </div>
                          <div v-else>{{ item.imeiNumber }}</div>
                        </div>
                      </td>
                      <td class="text-center">{{ item.qty }}</td>
                      <td class="text-right">
                        {{ item.price | currency }}
                      </td>
                      <td class="text-right">
                        {{ (item.price * item.qty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <v-card-text class="pr-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span class="font-weight-medium" v-if="Invoice.InvoiceDetails">
                  {{ totalQty }} ITEM(S) SOLD
                </span>

                <div class="signature">
                  <v-divider></v-divider>
                  <div>Signature</div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="7">
                <v-row>
                  <v-col>
                    <div class="font-weight-medium text-right">SUBTOTAL:</div>
                    <div class="font-weight-medium text-right">TOTAL PAID:</div>
                    <div class="font-weight-medium text-right">
                      INVOICE DUE AMOUNT:
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.total | currency }}
                    </div>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.payment | currency }}
                    </div>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.due | currency }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="font-weight-medium">POLICY</div>
            <v-divider></v-divider>
            <div style="line-height: 12px">
              <small>{{ currentBusiness.policy }}</small>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";

import invoiceService from "../service";
import { mapGetters, mapActions } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      showBarcode: false,
      showEmailBtn: false,
      isLoading: true,
      Invoice: {
        InvoiceDetails: [],
      },
      headers: [
        {
          text: "ITEM NAME",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "IMEI NUMBER",
          align: "center",
          value: "imei",
          sortable: false,
        },
        {
          text: "QTY",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "RATE",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  created() {
    if (this.$route.meta.public) {
      this.loadPublicData();
    } else {
      this.loadData();
      if (!this.currentBusiness) this.initUser();
    }
  },
  components: { PrintViewBar },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    print() {
      window.print();
    },
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);

      this.$Progress.start();
      return invoiceService.publicGetById(id).then((response) => {
        this.initBusiness(response.business);
        // // console.log("response packing slip", response);
        this.$Progress.finish();
        this.Invoice = response.data;
        if (this.Invoice ?.Customer ?.email) {
          this.showEmailBtn = true;
        }
        if (this.Invoice.InvoiceDetails) {
          this.Invoice.InvoiceDetails.map((row) => {
            this.totalQty += parseInt(row.qty);
          });
        }
        this.isLoading = false;
      });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);

      this.$Progress.start();
      return invoiceService.getById(id).then((response) => {
        // // console.log("response packing slip", response);
        this.$Progress.finish();
        this.Invoice = response.data;
        if (this.Invoice ?.Customer ?.email) {
          this.showEmailBtn = true;
        }
        if (this.Invoice.InvoiceDetails) {
          this.Invoice.InvoiceDetails.map((row) => {
            this.totalQty += parseInt(row.qty);
          });
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.signature {
  margin-top: 80px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
